



















































































































































































































import CommonUtils from '@/utils/common-utils';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import LessonServices from '@/services/lesson-services';
import ld from 'lodash';
import DateTimeUtils from '@/utils/date-time-utils';
import draggable from 'vuedraggable';
import LessonListItem from '@/components/lessonlists/LessonListItem.vue';
import LessonActions from '@/components/lessons/LessonActions.vue';
import SectionActions from '@/components/plans/card/SectionActions.vue';
import SectionAttachments from '@/components/plans/card/SectionAttachments.vue';
import SectionGroup from '@/components/plans/card/SectionGroup.vue';
import SectionStandards from '@/components/plans/card/SectionStandards.vue';
import SectionStatuses from '@/components/plans/card/SectionStatuses.vue';
import SectionText from '@/components/plans/card/SectionText.vue';
import SectionTitle from '@/components/plans/card/SectionTitle.vue';
import TableResizeMixin from '@/mixins/table-resize-mixin';
import { UserSession } from '@/common/user-session';
import { PlanbookStorage } from '@/common/planbook-storage';
import { infiniteScrollDelay } from '@/constants';
const settings = namespace('settings');
const lessonlists = namespace('lessonlists');
const lessons = namespace('lessons');
const classes = namespace('classes');
const units = namespace('units');
const plans = namespace('plans');
@Component({
  props: {
    items: {
      type: Array
    },
    search: {
      type: String
    },
    isCalledBylessonbanks: {
      type: Boolean
    },
    height: {
      type: String,
      default: 'calc(100vh - 145px)'
    },
    excludeHeaders: {
      type: Array,
      required: false,
      default: () => {
        return ['className']
      }
    }
  },
  components: {
    draggable,
    LessonListItem,
    LessonActions,
    SectionActions,
    SectionAttachments,
    SectionGroup,
    SectionStandards,
    SectionStatuses,
    SectionText,
    SectionTitle
  },
  mixins: [TableResizeMixin]
})
export default class LessonListsTable extends Vue {
  $refs!: {
    sentinel: any,
    scrollContainer:any,
    listRow:any
  }

  selectedRows: any = [];
  tableLessonBankItems: any[] = [];
  localSearch = '';
  selectedItem:any = false;
  localTitle = this.localPage;
  excludeHeaders!: Array<string>;

  headers:any[] = [];

  infiniteScrollList:any[] = [];
  isLoadingMore = false;
  canLoadMore = true;

  initialLoad = true;
  pageNumber = 0;
  pageCount = 0;
  listEndObserver:any = undefined;
  localIsEditMode = false;
  localInfiniteScrollResetAllowed = false;
  localEditingLesson = '';
  tableHeight!: any;
  autoScrollBottom = false;
  autoSave = false;
  sequenceChanged = false;
  lessonDeleted = false;

  @settings.Getter('getUserMode')
  userMode!: string;

  @units.Action('getUnit')
  getUnit!: (params?: number) => Promise<any>;

  @units.Getter('getUnits')
  localUnits!: Array<any>;

  @lessonlists.Getter('getSelectedLessonBankClassId')
  selectedLessonBankClassId!: number;

  @lessonlists.Getter('getLessonBanksView')
  lessonBanksView!: string;

  @lessonlists.Getter('getLessonListView')
  lessonListView!: string;

  @lessonlists.Getter('getCurrentSelectedClassColor')
  currentSelectedClassColor!: any;

  @settings.Getter('getSharedClasses')
  sharedClass!: any[];

  @settings.Getter('getDateStyling')
  dateStyling!: any;

  @settings.Getter
  getSharingOptionsSettings!: any;

  @settings.Getter('getBrowserHeight')
  browserHeight!: number;

  @lessons.Action('init')
  initLesson!: (params?: any) => Promise<any>;

  @lessons.Mutation
  setUnitId!: (value: number) => void;

  @lessons.Action('save')
  saveLesson!: (params?: any) => Promise<any>;

  @classes.Getter('getClassUnits')
  getClassUnits!: (classId: number) => any[];

  @plans.State
  viewType!: string;

  @Watch('items')
  updateTableItems(items:any) {
    if (PlanbookStorage.get('unitViewLessonsParams')) return;
    this.localTableItems = items;
    if (this.localInfiniteScrollResetAllowed) {
      if (this.initialLoad) {
        CommonUtils.showLoading();
      }
      this.resetInfiniteScrollData();
      this.initialize();
    } else {
      if (this.sequenceChanged) {
        this.updateAllListDataAndSort();
      } else {
        this.updateAllListData();
      }
      this.localInfiniteScrollResetAllowed = true;
    }
  }

  @Watch('search')
  watchSearch (v: any) {
    this.infiniteScrollList = this.localTableItems;
    this.canLoadMore = false;
    this.localSearch = v;
  }

  get hasText() {
    return CommonUtils.hasText;
  }

  get contentBreakpoint() {
    return CommonUtils.mainContentBreakpoint();
  }

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  get lessonUniqueIdentifier() {
    return LessonServices.getLessonUniqueIdentifier;
  }

  get sharedClassUnits():any[] {
    let sharedUnit:any = [];
    Object.entries(this.sharedClass).forEach((sharedClass: any) => {
      if (+sharedClass[0] === this.selectedLessonBankClassId) {
        sharedUnit = sharedClass[1].units;
      }
    });
    return sharedUnit;
  }

  get lessonBanksUnits():any[] {
    const allUnits:any[] = [];
    this.localUnits.forEach((unit: any) => {
      allUnits.push(unit);
    });
    this.sharedClassUnits.forEach((unit: any) => {
      if (!allUnits.some(au => +au.unitId === +unit.unitId)) {
        allUnits.push(unit);
      }
    });
    return allUnits;
  }

  units(item:any) {
    if (this.isOnLessonBanks) {
      const unit:any[] = []
      this.lessonBanksUnits.forEach((unitElement:any) => {
        if (+unitElement.subjectId === +this.selectedLessonBankClassId) {
          unit.push(unitElement);
        }
      });
      return unit.sort((u1, u2) => {
        return u1.unitNum.localeCompare(u2.unitNum);
      }) || [];
    } else {
      const classUnits = this.getClassUnits(item.classId || item.subjectId);
      for (const unit of classUnits) {
        if ((unit.id || unit.unitId) === item.unitId) {
          item.unitTitle = unit.title;
          item.unitNum = unit.num;
        }
      }
      return classUnits.sort((u1, u2) => {
        return u1.num.localeCompare(u2.num);
      }) || [];
    }
  }

  get isAllTitles() {
    if (this.isOnLessonBanks) {
      if (this.lessonBanksView === this.$t('allTitlesLabel')) {
        return true;
      } else {
        return false;
      }
    } else {
      if (this.lessonListView === this.$t('allTitlesLabel')) {
        return true;
      } else {
        return false;
      }
    }
  }

  get isDraggableDisabled() {
    if (this.localPage !== 'lesson-banks') {
      return true;
    } else {
      return false;
    }
  }

  get tableRowClass() {
    if (!this.isDraggableDisabled && !this.excludeHeaders.includes('sorter')) {
      return 'grabbable';
    } else {
      return '';
    }
  }

  get currentDateBackgroundColor() {
    return CommonUtils.getColor(this.getValue('currentDateBackgroundColor', this.dateStyling.currentDateBackgroundColor || 'inherit'));
  }

  get currentDateTextColor() {
    return CommonUtils.getColor(this.getValue('currentDateTextColor', this.dateStyling.currentDateTextColor || 'inherit'));
  }

  get currentDate() {
    const currentDate = new Date();
    const currentDay = (currentDate.getDate() < 10 ? '0' : '') + currentDate.getDate();
    const nCurrentMonth = currentDate.getMonth() + 1;
    const currentMonth = (nCurrentMonth < 10 ? '0' : '') + nCurrentMonth;
    const currentDatemmddyyyy = currentMonth + '/' + currentDay + '/' + currentDate.getFullYear();
    return currentDatemmddyyyy;
  }

  get updatedSettings(): any {
    return this.$store.state.settings.updatedSettings;
  }

  get localTableItems () {
    return this.tableLessonBankItems
  }

  set localTableItems(val:any) {
    this.tableLessonBankItems = val;
  }

  get stringifyDate() {
    return DateTimeUtils.stringifyDate;
  }

  get isOnLessonBanks () {
    if (this.localTitle === 'lesson-banks') {
      return true;
    } else {
      return false;
    }
  }

  get darkMode(): boolean {
    return this.$vuetify.theme.dark;
  }

  get allowAllDataToLoad() {
    const isMacOS = /macintosh|mac os x/i.test(navigator.userAgent);
    return !isMacOS && this.browserHeight < 700;
  }

  isColorReadable(fg: string, bg?: string) {
    return CommonUtils.isColorReadable(fg, bg);
  }

  updateAllListData() {
    const that = this;
    this.infiniteScrollList.forEach((l:any, index:number) => {
      const updatedData = that.localTableItems[index];
      if (updatedData !== undefined) {
        Vue.set(that.infiniteScrollList, index, updatedData);
      } else {
        that.infiniteScrollList.splice(index, 1);
      }
    });
    this.lessonDeleted = false;
  }

  updateAllListDataAndSort() {
    const that = this;
    this.infiniteScrollList.forEach((l:any, index:number) => {
      let updatedData;
      for (const lessonList of that.localTableItems) {
        if (this.lessonUniqueIdentifier(l) === that.lessonUniqueIdentifier(lessonList)) {
          updatedData = lessonList;
        }
      }
      if (updatedData) {
        Vue.set(that.infiniteScrollList, index, updatedData);
      }
    });
    this.infiniteScrollList = this.infiniteScrollList.sort((a, b) => a.lessonNum - b.lessonNum);
    this.sequenceChanged = false;
  }

  resetInfiniteScrollData() {
    this.infiniteScrollList = [];
    if (this.listEndObserver) {
      this.listEndObserver.disconnect();
    }
    this.isLoadingMore = false;
    this.canLoadMore = true;

    this.initialLoad = true;
    this.pageNumber = 0;
    this.pageCount = 0;
    this.listEndObserver = undefined;
    this.canLoadMore = true;
  }

  openLessonViewMode(item:any, arrowClicked?:boolean) {
    this.$eventBus.$emit('closeSubPageLessonBank', { item: item, arrowClicked: arrowClicked, isEditMode: false });
  }

  openLessonEditMode(item:any, arrowClicked?:boolean, autoSave?:boolean) {
    this.$eventBus.$emit('closeSubPageLessonBank', { item: item, arrowClicked: arrowClicked, isEditMode: true, autoSave });
  }

  getUnitSelectBGColor(data:any) {
    if (this.isOnLessonBanks) {
      if (data.unit && data.unit.unitColor) {
        return data.unit.unitColor;
      } else if (data.titleFillColor) {
        return data.titleFillColor;
      } else {
        return this.$vuetify.theme.currentTheme.primary;
      }
    } else {
      return this.currentSelectedClassColor;
    }
  }

  getUnitSelectColor(data:any) {
    if (+data.unitId > 0) {
      return this.isColorReadable('white', this.getUnitSelectBGColor(data)) ? 'white' : 'black';
    } else {
      return 'white';
    }
  }

  getLocalDayObject(data:any, dayObject:any) {
    const rawDayObject = ld.cloneDeep(data);
    delete rawDayObject.dayObject;
    const obj = {
      ...dayObject,
      rawDayObject: rawDayObject
    }
    return obj
  }

  selectUnit(lesson: any, unit: any) {
    CommonUtils.showLoading();
    return this.initLesson({ data: ld.cloneDeep(lesson), loadData: true }).then(async () => {
      let unitId = 0;
      if (this.isOnLessonBanks) {
        unitId = unit.unitId;
      } else {
        unitId = unit.id;
      }
      this.setUnitId(unitId);
      return this.saveLesson();
    }).finally(() => {
      lesson.unitNum = unit.num || unit.unitNum;
      lesson.unitId = unit.id || unit.unitId;
      lesson.unitTitle = unit.unitTitle;
      lesson.unit = ld.cloneDeep(unit);
      CommonUtils.hideLoading();
    });
  }

  getActionBGColor(data:any) {
    if (this.isOnLessonBanks) {
      if (data.titleFillColor) {
        return data.titleFillColor;
      } else {
        return this.$vuetify.theme.currentTheme.primary;
      }
    } else {
      if (data.color) {
        return data.color;
      } else {
        return this.$vuetify.theme.currentTheme.primary;
      }
    }
  }

  isUntitledLesson(item:any) {
    return this.hasLessonId(item) && CommonUtils.hasNoText(item.lessonTitle);
  }

  isEmptyLesson(item:any) {
    return !this.hasLessonId(item);
  }

  hasLessonId(item:any) {
    if (+item.lessonId > 0) {
      return true;
    } else {
      return false;
    }
  }

  getClassName(item: any) {
    if (CommonUtils.hasText(item.yearName)) {
      return item.className + ' (' + item.yearName + ')';
    }
    return item.className;
  }

  hasData(lesson:any) {
    return LessonServices.hasLessonData(lesson);
  }

  getDateBgColor(date:any) {
    if (date === this.currentDate) {
      return this.currentDateBackgroundColor;
    }
  }

  getDateTextColor(date:any) {
    if (date === this.currentDate) {
      return this.currentDateTextColor;
    }
  }

  getValue(key: string, defaultValue: any) {
    if (Object.prototype.hasOwnProperty.call(this.updatedSettings, key)) {
      return this.updatedSettings[key];
    }
    return defaultValue;
  }

  hoverEnterEdit(item: any) {
    this.selectedItem = this.lessonUniqueIdentifier(item);
  }

  hoverExitEdit() {
    this.selectedItem = false;
  }

  downArrowClick(item:any) {
    const that = this;
    const index = that.localTableItems.findIndex((l:any) => {
      return that.lessonUniqueIdentifier(l) === that.lessonUniqueIdentifier(item);
    });
    if (index < (that.infiniteScrollList.length - 1)) {
      this.$refs.listRow.forEach((row:any, index:number) => {
        try {
          if (row.id === that.lessonUniqueIdentifier(item)) {
            row.bgColor = '';
            const elementToFocus:any = document.getElementById(this.$refs.listRow[index + 1].id);
            this.infiniteScrollList.forEach((listData:any, indexList:number) => {
              if (row.id === that.lessonUniqueIdentifier(listData)) {
                that.$refs.listRow[index + 1].bgColor = this.darkMode ? '#434343' : '#eeeeee';
                elementToFocus.focus();
                if (that.localIsEditMode) {
                  that.openLessonEditMode(that.infiniteScrollList[indexList + 1], true);
                } else {
                  that.openLessonViewMode(that.infiniteScrollList[indexList + 1], true);
                }
              }
            });
          }
        } catch (e) {
          console.log('Loading...');
        }
      });
    }
  }

  upArrowClick(item:any) {
    const that = this;
    const index = that.localTableItems.findIndex((l:any) => {
      return that.lessonUniqueIdentifier(l) === that.lessonUniqueIdentifier(item);
    });
    if (index !== 0) {
      this.$refs.listRow.forEach((row:any, index:number) => {
        try {
          if (row.id === that.lessonUniqueIdentifier(item)) {
            row.bgColor = '';
            const elementToFocus:any = document.getElementById(this.$refs.listRow[index - 1].id);
            this.infiniteScrollList.forEach((listData:any, indexList:number) => {
              if (row.id === that.lessonUniqueIdentifier(listData)) {
                that.$refs.listRow[index - 1].bgColor = this.darkMode ? '#434343' : '#eeeeee';
                elementToFocus.focus();
                if (that.localIsEditMode) {
                  that.openLessonEditMode(that.infiniteScrollList[indexList - 1], true);
                } else {
                  that.openLessonViewMode(that.infiniteScrollList[indexList - 1], true);
                }
              }
            });
          }
        } catch (e) {
          console.log('Loading...');
        }
      });
    }
  }

  private viewUnits(item:any) {
    const that = this;
    CommonUtils.showLoading();
    this.getUnit(+item.unitId).then((resp:any) => {
      that.$eventBus.$emit('openSubPage', {
        type: 'unit',
        width: 600,
        modal: false,
        input: resp.data
      });
      CommonUtils.hideLoading();
    })
  }

  dragDrop(evt: any) {
    const newBankItems = JSON.stringify(this.infiniteScrollList);
    const oldBankItems = JSON.stringify(this.tableLessonBankItems);
    if (newBankItems !== oldBankItems) {
      this.sequenceChanged = true;
      this.$emit('dragDrop', evt, this.infiniteScrollList);
    }
  }

  moveSectionUp(index: any) {
    const clone = ld.cloneDeep(this.infiniteScrollList);
    clone[index - 1] = this.infiniteScrollList[index];
    clone[index] = this.infiniteScrollList[index - 1];
    this.infiniteScrollList = clone;
    this.dragDrop(null);
  }

  moveSectionDown(index: any) {
    const clone = ld.cloneDeep(this.infiniteScrollList);
    clone[index + 1] = this.infiniteScrollList[index];
    clone[index] = this.infiniteScrollList[index + 1];
    this.infiniteScrollList = clone;
    this.dragDrop(null);
  }

  private isClassFromPermissions(classId:any) {
    let valueTobeReturned = false;
    this.getSharingOptionsSettings.sharedList.forEach((shared:any) => {
      shared.years.forEach((year:any) => {
        year.classes.forEach((classes:any) => {
          if (+classes.classId === +classId) {
            if (classes.fromPermissions) {
              valueTobeReturned = true;
            }
          }
        });
      });
    });
    return valueTobeReturned;
  }

  getLessonInput(itemData: any) {
    this.localEditingLesson = this.lessonUniqueIdentifier(itemData);
    const excludes = LessonServices.excludedLessonActions(itemData, this.userMode, this.localPage);
    return {
      exclude: excludes,
      loadData: true,
      data: itemData,
      lessonListsView: true
    }
  }

  showActions(event: any, itemData: any) {
    this.localEditingLesson = this.lessonUniqueIdentifier(itemData);
    const excludes = LessonServices.excludedLessonActions(itemData, this.userMode, this.localPage);
    this.$eventBus.$emit('actions', {
      type: 'lesson',
      event,
      input: {
        exclude: excludes,
        loadData: true,
        data: itemData,
        lessonListsView: true
      }
    });
  }

  onSectionCardClicked(e: any) {
    const excludes = LessonServices.excludedLessonActions(e.dayObject.rawDayObject, this.userMode, this.localPage);
    const event = e.event;
    const dayObject = e.dayObject;
    const target = event.target;
    const rawDayObject = ld.cloneDeep(e.dayObject.rawDayObject);
    if (rawDayObject.type === 'E' && rawDayObject.readonly) return;
    if (rawDayObject.type === 'E' && CommonUtils.hasText(rawDayObject.googleId)) {
      this.$eventBus.$emit('actions', {
        type: 'googleEvent',
        event,
        input: {
          mode: 'P',
          loadData: true,
          data: rawDayObject
        }
      });
    } else if (target.matches('.section-edit-link-lesson') || target.matches('.section-edit-link-lesson .fa-link')) {
      if (this.userMode === 'T') {
        this.$eventBus.$emit('openSubPage', {
          type: 'lesson',
          modal: true,
          input: {
            loadData: true,
            data: rawDayObject,
            lessonListsView: true
          }
        });
      }
    } else if (target.matches('.section-show-actions') || target.matches('.section-show-actions .fa-ellipsis-v')) {
      if (CommonUtils.hasNoText(rawDayObject.googleId)) {
        this.$eventBus.$emit('actions', {
          type: rawDayObject.type === 'E' ? 'event' : 'lesson',
          event,
          input: {
            mode: 'P',
            loadData: true,
            data: rawDayObject,
            exclude: excludes,
            lessonListsView: true
          }
        });
      }
    } else if (target.matches('a')) {
      this.openLink(target, dayObject.rawDayObject);
    } else if (target.matches('.section-title[data-type="unit"]') || target.closest('.section-title[data-type="unit"]')) {
      const unit = rawDayObject.unit;
      this.$eventBus.$emit('openSubPage', {
        type: 'unit',
        modal: true,
        input: unit
      });
    } else if (target.matches('.section-title[data-type="linkedUnit"]') || target.closest('.section-title[data-type="linkedUnit"]')) {
      const linkedUnit = rawDayObject.linkedUnit;
      this.$eventBus.$emit('openSubPage', {
        type: 'unit',
        modal: true,
        input: linkedUnit
      });
    } else if (target.matches('.section-action') && CommonUtils.hasText(target.getAttribute('url'))) {
      window.open(target.getAttribute('url'), '_blank');
    } else if (dayObject.rawDayObject.type === 'GC') {
      window.open(dayObject.rawDayObject.courseUrl, '_blank');
    } else if (rawDayObject.type === 'E') {
      if (CommonUtils.hasNoText(rawDayObject.googleId) && ((rawDayObject.schoolId === 0 && this.userMode === 'T') || (rawDayObject.schoolId > 0 && this.userMode === 'A'))) {
        this.$eventBus.$emit('openSubPage', {
          type: 'event',
          modal: true,
          input: {
            loadData: true,
            data: rawDayObject
          }
        });
      }
    } else {
      if (this.$currentUser.isTeacher || (this.$currentUser.isAdmin && this.localPage === 'lesson-banks')) {
        const isModal = this.$currentUser.defaultEditorMode === 'modal';
        this.$eventBus.$emit('openSubPage', {
          type: 'lesson',
          width: isModal ? undefined : 700,
          modal: isModal,
          input: {
            loadData: true,
            data: rawDayObject,
            lessonListsView: true
          }
        });
      }
    }
  }

  openLink(a: HTMLAnchorElement, dayObject: any) {
    const url = a.href;
    const downloadForm = document.getElementById('downloadForm') as HTMLFormElement;
    if (CommonUtils.hasText(url)) {
      window.open(url, '_blank');
    } else {
      downloadForm.innerHTML = '';
      const attachmentName = document.createElement('input');
      attachmentName.setAttribute('name', 'attachmentName');
      attachmentName.setAttribute('value', a.text);
      downloadForm.appendChild(attachmentName);

      const teacherId = document.createElement('input');
      teacherId.setAttribute('name', 'teacherId');
      teacherId.setAttribute('value', dayObject.teacherId);
      downloadForm.appendChild(teacherId);

      const collaborateSubjectId = document.createElement('input');
      collaborateSubjectId.setAttribute('name', 'collaborateSubjectId');
      collaborateSubjectId.setAttribute('value', dayObject.collaborateSubjectId);
      downloadForm.appendChild(collaborateSubjectId);

      const accessToken = document.createElement('input');
      accessToken.setAttribute('name', 'X-PB-ACCESS-TOKEN');
      accessToken.setAttribute('value', UserSession.getAccessToken());
      downloadForm.appendChild(accessToken);

      const yearId = document.createElement('input');
      accessToken.setAttribute('name', 'X-PB-CLIENT-YEAR-ID');
      accessToken.setAttribute('value', UserSession.getCurrentYearIdAsString());
      downloadForm.appendChild(yearId);

      downloadForm.submit();
    }
  }

  mounted() {
    this.localInfiniteScrollResetAllowed = true;
    this.localTableItems = this.$props.items;
    this.$eventBus.$on('lessonEditorClosed', () => {
      this.localEditingLesson = '';
      if (this.$refs.listRow) {
        for (const row of this.$refs.listRow) {
          row.bgColor = '';
        }
      }
    });
    this.$eventBus.$on('openSubPageLessonBank', (payload?: any) => {
      this.$nextTick(() => {
        this.localEditingLesson = this.lessonUniqueIdentifier(payload.item);
        this.localIsEditMode = payload.isEditMode;
        const dayObject = this.getLocalDayObject(payload.item, payload.item.dayObject);
        if (!payload.isEditMode) {
          this.$eventBus.$emit('openSubPage', {
            type: 'lessonViewMode',
            width: 600,
            modal: false,
            input: {
              rawDayObject: payload.item,
              lessonListsView: true
            }
          });
        } else {
          this.initLesson({ loadData: true, data: dayObject.rawDayObject });
          this.$eventBus.$emit('openSubPage', {
            type: 'lesson',
            width: 600,
            modal: this.$currentUser.defaultEditorMode === 'modal',
            input: {
              loadData: true,
              data: dayObject.rawDayObject,
              dayObject: dayObject,
              readonly: !payload.isEditMode,
              lessonListsView: true,
              itemData: payload.item,
              skipDirtyCheck: payload.arrowClicked
            }
          });
        }
        if (!payload.arrowClicked) {
          for (const row of this.$refs.listRow) {
            if (row.id === this.lessonUniqueIdentifier(payload.item)) {
              row.bgColor = this.darkMode ? '#434343' : '#eeeeee'
            } else {
              row.bgColor = '';
            }
          }
        }
      });
    });
    this.$eventBus.$on('onSectionCardClicked', this.onSectionCardClicked);
    this.$eventBus.$on('previousLesson', (payload:any) => {
      this.localIsEditMode = payload.isEditMode;
      this.upArrowClick(payload.item);
    });
    this.$eventBus.$on('nextLesson', (payload:any) => {
      this.localIsEditMode = payload.isEditMode;
      this.downArrowClick(payload.item);
    });
  }

  destroyed() {
    if (this.listEndObserver) {
      this.listEndObserver.disconnect();
    }
    this.$eventBus.$off('lessonEditorClosed');
    this.$eventBus.$off('openSubPageLessonBank');
    this.$eventBus.$off('onSectionCardClicked');
    this.$eventBus.$off('previousLesson');
    this.$eventBus.$off('nextLesson');
  }

  initialize() {
    if (this.isDraggableDisabled || this.selectedLessonBankClassId === 0) {
      this.headers = [
        { text: this.$t('seqNumLabel'), value: 'extraLesson', width: 10, sortable: false },
        { text: this.$t('classLabel'), value: 'className', width: 180, sortable: false },
        {
          text: this.$t('unitLabel'),
          value: 'lessonNum',
          width: 80,
          sortable: false
        },
        {
          text: this.$t('dateLabel'),
          value: 'date',
          width: 110,
          align: 'center',
          sortable: false
        },
        {
          text: this.$t('dayLabel'),
          value: 'day',
          width: 50,
          align: 'center',
          sortable: false
        },
        {
          text: this.$t('lessonLabel'),
          value: 'lessonTitle',
          sortable: false
        },
        { width: 10, sortable: false, value: 'sorter' },
        { sortable: false, width: 10, value: 'action' }]
    } else {
      this.headers = [
        { text: this.$t('seqNumLabel'), value: 'extraLesson', width: 10, sortable: false },
        { text: this.$t('classLabel'), value: 'className', width: 180, sortable: false },
        {
          text: this.$t('unitLabel'),
          value: 'lessonNum',
          width: 80,
          sortable: false
        },
        {
          text: this.$t('lessonLabel'),
          value: 'lessonTitle',
          sortable: false
        },
        { width: 10, sortable: false, value: 'sorter' },
        { sortable: false, width: 10, value: 'action' }]
    }
    this.headers = this.headers.filter(h => !this.excludeHeaders.includes(h.value));

    const that = this;
    if (CommonUtils.isNotEmpty(this.localTableItems)) {
      const scrollContainer = that.$refs.scrollContainer;
      const tableRowHeight = 48;

      const itemsPerScreen = (Math.round(scrollContainer.clientHeight / tableRowHeight));
      this.pageCount = Math.round(this.localTableItems.length / itemsPerScreen);
      this.pageNumber = 0;
      this.setInfiniteScrollInitialData();
    } else {
      if (that.initialLoad) {
        CommonUtils.hideLoading();
      }
      this.initialLoad = false;
      this.canLoadMore = false;
    }
  }

  setInfiniteScrollInitialData() {
    const that = this;
    this.fetchMoreItems().then((items:any) => {
      that.infiniteScrollList.push(...items);
      if (that.initialLoad) {
        CommonUtils.hideLoading();
      }
      that.initialLoad = false;
      // wait for initial list to render and then set up observer
      that.$nextTick(() => {
        that.setUpInterSectionObserver();
        const lessonToOpen = that.autoScrollBottom ? that.infiniteScrollList[that.infiniteScrollList.length - 1] : that.infiniteScrollList[0];
        if (!that.autoSave) {
          that.openLessonViewMode(lessonToOpen, false);
        }
        if (that.autoScrollBottom) {
          setTimeout(() => {
            const listRow = that.$refs.listRow;
            listRow[listRow.length - 1].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            that.autoScrollBottom = false;
          }, 500);
        }
        that.autoSave = false;
      })
    });
  }

  fetchMoreItems() {
    const that = this;
    const scrollContainer = that.$refs.scrollContainer;
    const tableRowHeight = 48;

    const itemsPerScreen = (Math.round(scrollContainer.clientHeight / tableRowHeight));
    return new Promise((resolve, reject) => {
      const data:any = [];
      let indexOfLastData = 0;
      if (CommonUtils.isNotEmpty(that.infiniteScrollList)) {
        indexOfLastData = that.localTableItems.findIndex((l:any) => {
          return that.lessonUniqueIdentifier(l) === that.lessonUniqueIdentifier(that.infiniteScrollList[that.infiniteScrollList.length - 1])
        });
        indexOfLastData++;
      }
      if (((that.localTableItems.length - indexOfLastData) < itemsPerScreen) || that.autoScrollBottom) {
        for (let index = indexOfLastData; index <= that.localTableItems.length - 1; index++) {
          data.push(that.localTableItems[index]);
        }
      } else {
        for (let i = 1; i <= itemsPerScreen; i++) {
          data.push(that.localTableItems[indexOfLastData]);
          indexOfLastData++;
        }
      }

      setTimeout(() => {
        if (CommonUtils.isNotEmpty(data)) {
          resolve(data);
        } else {
          reject(new Error('No more items to load at the Bottom'));
        }
      }, infiniteScrollDelay);
    });
  }

  setUpInterSectionObserver() {
    const options = {
      root: this.$refs.scrollContainer,
      margin: '30px'
    };
    this.listEndObserver = new IntersectionObserver(
      this.handleIntersection,
      options
    );

    this.listEndObserver.observe(this.$refs.sentinel);
  }

  handleIntersection([entry]:any) {
    if (entry.isIntersecting) {
      console.log('sentinel intersecting');
    }
    if (entry.isIntersecting && this.canLoadMore && !this.isLoadingMore) {
      this.loadMore();
    }
  }

  async loadAll() {
    while (this.canLoadMore) {
      await this.loadMore();
    }
  }

  async loadMore() {
    try {
      this.isLoadingMore = true;
      const items:any = await this.fetchMoreItems();
      console.log('loaded page ' + this.pageNumber);
      this.pageNumber++;
      this.infiniteScrollList.push(...items);
    } catch (error) {
      console.log('Reached end of page', error);
      console.log('total items in the page ' + this.infiniteScrollList.length);
      this.canLoadMore = false;
    } finally {
      this.isLoadingMore = false;
    }
  }

  lessonTextField(item:any): string {
    let textField = '';
    for (const content of item.dayObject.sections) {
      if (content.hide === 'N' && content.type !== 'section-statuses') {
        if (content.items) {
          for (const cItem of content.items) {
            if (CommonUtils.hasText(cItem.text)) {
              textField = this.firstHundredChar(cItem.text);
              break;
            }
          }
        }

        if (CommonUtils.hasText(textField)) {
          break;
        }
      }
    }
    return textField;
  }

  unitSectionsTextField(item:any): string {
    let textField = '';
    if (item.unit) {
      for (const content of item.unit.contents) {
        if (CommonUtils.hasText(content.value)) {
          textField = this.firstHundredChar(content.value);
          break;
        }
      }
    }
    return textField;
  }

  linkedUnitSectionsTextField(item:any): string {
    let textField = '';
    if (item.linkedUnit) {
      for (const content of item.linkedUnit.contents) {
        if (CommonUtils.hasText(content.value)) {
          textField = this.firstHundredChar(content.value);
          break;
        }
      }
    }
    return textField;
  }

  firstHundredChar(html:string) {
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(html, 'text/html');
    const textContent = parsedHtml.body.textContent || '';
    return textContent.substring(0, 100);
  }
}
