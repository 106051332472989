var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "scrollContainer", staticClass: "scroll-container" },
    [
      _c("v-data-table", {
        ref: "table",
        attrs: {
          headers: _vm.headers,
          items: _vm.infiniteScrollList,
          search: _vm.localSearch,
          "item-key": "extraLesson",
          height: _vm.tableHeight,
          "fixed-header": "",
          "show-expand": "",
          "hide-default-footer": "",
          "disable-pagination": "",
          loading: _vm.initialLoad,
          "hide-default-header": ""
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function(ref) {
              var items = ref.items
              var headers = ref.headers
              return [
                _vm.infiniteScrollList.length > 0
                  ? _c(
                      "draggable",
                      {
                        attrs: {
                          tag: "tbody",
                          disabled:
                            _vm.isDraggableDisabled &&
                            _vm.excludeHeaders.includes("sorter"),
                          animation: 200,
                          forceFallback: true,
                          delayOnTouchOnly: true,
                          delay: 80
                        },
                        on: { end: _vm.dragDrop },
                        model: {
                          value: _vm.infiniteScrollList,
                          callback: function($$v) {
                            _vm.infiniteScrollList = $$v
                          },
                          expression: "infiniteScrollList"
                        }
                      },
                      [
                        _vm._l(items, function(item, index) {
                          return _c(
                            "tr",
                            {
                              key: index,
                              ref: "listRow",
                              refInFor: true,
                              class: _vm.tableRowClass,
                              attrs: {
                                id: _vm.lessonUniqueIdentifier(item),
                                tabindex: "0",
                                role: "row"
                              },
                              on: {
                                keydown: [
                                  function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "arrow-down",
                                        undefined,
                                        $event.key,
                                        undefined
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.downArrowClick(item)
                                  },
                                  function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "arrow-up",
                                        undefined,
                                        $event.key,
                                        undefined
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.upArrowClick(item)
                                  }
                                ],
                                mouseenter: function($event) {
                                  return _vm.hoverEnterEdit(item)
                                },
                                mouseleave: _vm.hoverExitEdit,
                                focus: function($event) {
                                  return _vm.hoverEnterEdit(item)
                                },
                                blur: _vm.hoverExitEdit,
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.openLessonViewMode(item)
                                },
                                dblclick: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.openLessonEditMode(item)
                                },
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.openLessonEditMode(item)
                                },
                                contextmenu: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.showActions($event, item)
                                }
                              }
                            },
                            [
                              !_vm.excludeHeaders.includes("extraLesson")
                                ? _c(
                                    "td",
                                    {
                                      style:
                                        _vm.contentBreakpoint === "xs"
                                          ? "width: 50px;"
                                          : "max-width: 100px; width: 50px;"
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(item.lessonNum))
                                      ])
                                    ]
                                  )
                                : _vm._e(),
                              !_vm.excludeHeaders.includes("className")
                                ? _c(
                                    "td",
                                    {
                                      style:
                                        _vm.contentBreakpoint === "xs"
                                          ? ""
                                          : "max-width: 100px;"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: {
                                            align: "start",
                                            justify: "center"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "v-chip",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    color:
                                                      item.searchColor ||
                                                      item.color ||
                                                      _vm.$vuetify.theme
                                                        .currentTheme.primary,
                                                    small: "",
                                                    pill: "",
                                                    "text-color": "white"
                                                  }
                                                },
                                                [_vm._v(_vm._s(item.className))]
                                              ),
                                              _c("span", [
                                                _vm._v(_vm._s(item.yearName))
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              !_vm.excludeHeaders.includes("lessonNum")
                                ? _c("td", [
                                    _c(
                                      "div",
                                      {
                                        attrs: { align: "center" },
                                        on: {
                                          keydown: [
                                            function($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "down",
                                                  40,
                                                  $event.key,
                                                  ["Down", "ArrowDown"]
                                                )
                                              ) {
                                                return null
                                              }
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                            },
                                            function($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "up",
                                                  38,
                                                  $event.key,
                                                  ["Up", "ArrowUp"]
                                                )
                                              ) {
                                                return null
                                              }
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                            }
                                          ],
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                          }
                                        }
                                      },
                                      [
                                        _vm.units(item).length > 0
                                          ? _c(
                                              "v-menu",
                                              {
                                                attrs: {
                                                  disabled: _vm.excludeHeaders.includes(
                                                    "sorter"
                                                  ),
                                                  rounded: "",
                                                  "offset-y": ""
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var attrs = ref.attrs
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  class:
                                                                    +item.unitId >
                                                                      0 &&
                                                                    !_vm.isColorReadable(
                                                                      _vm.getUnitSelectBGColor(
                                                                        item
                                                                      )
                                                                    )
                                                                      ? "pb-border"
                                                                      : "",
                                                                  style: {
                                                                    color: _vm.getUnitSelectColor(
                                                                      item
                                                                    )
                                                                  },
                                                                  attrs: {
                                                                    depressed:
                                                                      "",
                                                                    rounded: "",
                                                                    "x-small":
                                                                      "",
                                                                    color:
                                                                      +item.unitId >
                                                                      0
                                                                        ? _vm.getUnitSelectBGColor(
                                                                            item
                                                                          )
                                                                        : "#b5bac1"
                                                                  },
                                                                  on: {
                                                                    focus: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.hoverEnterEdit(
                                                                        item
                                                                      )
                                                                    },
                                                                    blur:
                                                                      _vm.hoverExitEdit
                                                                  }
                                                                },
                                                                "v-btn",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.unitNum ||
                                                                      _vm.$t(
                                                                        "unitPlusLabel"
                                                                      )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _c(
                                                  "v-list",
                                                  [
                                                    _c(
                                                      "v-list-item",
                                                      {
                                                        class: {
                                                          "primary--text v-list-item--active v-list-item--link theme--light v-list-item--highlighted":
                                                            item.unitId == 0
                                                        },
                                                        attrs: { link: "" },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.selectUnit(
                                                              item,
                                                              { id: 0 }
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "noneLabel"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _vm._l(
                                                      _vm.units(item),
                                                      function(unit) {
                                                        return _c(
                                                          "v-list-item",
                                                          {
                                                            key: unit.unitId,
                                                            class: {
                                                              "primary--text v-list-item--active v-list-item--link theme--light v-list-item--highlighted":
                                                                item.unitId ==
                                                                (unit.id ||
                                                                  unit.unitId)
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.selectUnit(
                                                                  item,
                                                                  unit
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    unit.num ||
                                                                      unit.unitNum
                                                                  ) +
                                                                    " - " +
                                                                    _vm._s(
                                                                      unit.title ||
                                                                        unit.unitTitle
                                                                    )
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    )
                                                  ],
                                                  2
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ])
                                : _vm._e(),
                              _vm.isDraggableDisabled &&
                              !_vm.excludeHeaders.includes("date")
                                ? _c(
                                    "td",
                                    {
                                      style: {
                                        backgroundColor: _vm.getDateBgColor(
                                          item.date
                                        ),
                                        color: _vm.getDateTextColor(item.date)
                                      }
                                    },
                                    [
                                      item.yearId > 0
                                        ? _c(
                                            "div",
                                            {
                                              attrs: {
                                                align: "center",
                                                justify: "center"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.stringifyDate(
                                                      item.date,
                                                      true
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e(),
                              _vm.isDraggableDisabled &&
                              !_vm.excludeHeaders.includes("day")
                                ? _c(
                                    "td",
                                    {
                                      attrs: {
                                        align: "center",
                                        justify: "center"
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(item.dayOfWeek) + " ")]
                                  )
                                : _vm._e(),
                              !_vm.excludeHeaders.includes("lessonTitle")
                                ? _c(
                                    "td",
                                    {
                                      style:
                                        _vm.contentBreakpoint === "xs"
                                          ? "width: 100%;"
                                          : ""
                                    },
                                    [
                                      _vm.isUntitledLesson(item)
                                        ? _c("div", [
                                            _vm.hasText(
                                              _vm.lessonTextField(item)
                                            )
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.firstHundredChar(
                                                        _vm.lessonTextField(
                                                          item
                                                        )
                                                      )
                                                    )
                                                  )
                                                ])
                                              : _vm.hasText(
                                                  item.linkedLessonTitle
                                                )
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.linkedLessonTitle
                                                    )
                                                  )
                                                ])
                                              : _vm.hasText(
                                                  item.linkedLessonText
                                                )
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.firstHundredChar(
                                                        item.linkedLessonText
                                                      )
                                                    )
                                                  )
                                                ])
                                              : _vm.hasText(item.unitTitle)
                                              ? _c("span", [
                                                  _vm._v(_vm._s(item.unitTitle))
                                                ])
                                              : item.unitId > 0 &&
                                                _vm.hasText(
                                                  _vm.unitSectionsTextField(
                                                    item
                                                  )
                                                )
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.firstHundredChar(
                                                        _vm.unitSectionsTextField(
                                                          item
                                                        )
                                                      )
                                                    )
                                                  )
                                                ])
                                              : item.linkedUnit &&
                                                _vm.hasText(
                                                  item.linkedUnit.unitTitle
                                                )
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.linkedUnit.unitTitle
                                                    )
                                                  )
                                                ])
                                              : item.linkedUnitId > 0 &&
                                                _vm.hasText(
                                                  _vm.linkedUnitSectionsTextField(
                                                    item
                                                  )
                                                )
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.firstHundredChar(
                                                        _vm.linkedUnitSectionsTextField(
                                                          item
                                                        )
                                                      )
                                                    )
                                                  )
                                                ])
                                              : _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "untitledLessonsLabel"
                                                      )
                                                    )
                                                  )
                                                ])
                                          ])
                                        : _vm.isEmptyLesson(item)
                                        ? _c(
                                            "span",
                                            {
                                              style: {
                                                color:
                                                  _vm.$vuetify.theme
                                                    .currentTheme.info
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("emptyLessonLabel")
                                                )
                                              )
                                            ]
                                          )
                                        : _c("strong", [
                                            _vm._v(_vm._s(item.lessonTitle))
                                          ])
                                    ]
                                  )
                                : _vm._e(),
                              !_vm.excludeHeaders.includes("sorter")
                                ? _c(
                                    "td",
                                    {
                                      style:
                                        _vm.contentBreakpoint === "xs"
                                          ? ""
                                          : "max-width: 30px;"
                                    },
                                    [
                                      !_vm.isDraggableDisabled
                                        ? _c(
                                            "v-menu",
                                            {
                                              attrs: {
                                                bottom: "",
                                                "offset-y": ""
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var onMenu = ref.on
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "focus-icon-border",
                                                              attrs: {
                                                                small: "",
                                                                color: "#b5bac1"
                                                              },
                                                              on: {
                                                                keyup: function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "enter",
                                                                      13,
                                                                      $event.key,
                                                                      "Enter"
                                                                    )
                                                                  ) {
                                                                    return null
                                                                  }
                                                                  $event.stopPropagation()
                                                                }
                                                              }
                                                            },
                                                            onMenu
                                                          ),
                                                          [
                                                            _vm._v(
                                                              " fas fa-exchange-alt fa-rotate-90 "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _c(
                                                "v-list",
                                                [
                                                  index !== 0
                                                    ? _c(
                                                        "v-list-item",
                                                        {
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.moveSectionUp(
                                                                index
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "moveUpLabel"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  index !== items.length - 1
                                                    ? _c(
                                                        "v-list-item",
                                                        {
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.moveSectionDown(
                                                                index
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "moveDownLabel"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.excludeHeaders.includes("action")
                                ? _c(
                                    "td",
                                    {
                                      style:
                                        _vm.contentBreakpoint === "xs"
                                          ? ""
                                          : "max-width: 30px;",
                                      on: {
                                        keydown: [
                                          function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "down",
                                                40,
                                                $event.key,
                                                ["Down", "ArrowDown"]
                                              )
                                            ) {
                                              return null
                                            }
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                          },
                                          function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "up",
                                                38,
                                                $event.key,
                                                ["Up", "ArrowUp"]
                                              )
                                            ) {
                                              return null
                                            }
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                          }
                                        ],
                                        keyup: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: {
                                            align: "start",
                                            justify: "center"
                                          }
                                        },
                                        [
                                          _c("lesson-actions", {
                                            attrs: {
                                              type: "custom",
                                              input: _vm.getLessonInput(item)
                                            },
                                            on: {
                                              focus: function($event) {
                                                return _vm.hoverEnterEdit(item)
                                              },
                                              blur: _vm.hoverExitEdit
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "customButton",
                                                  fn: function() {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticStyle: {
                                                            cursor: "pointer",
                                                            padding: "4px"
                                                          },
                                                          attrs: {
                                                            size: "20",
                                                            color:
                                                              _vm.lessonUniqueIdentifier(
                                                                item
                                                              ) ===
                                                                _vm.selectedItem ||
                                                              _vm.contentBreakpoint ===
                                                                "xs"
                                                                ? ""
                                                                : "transparent"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " fas fa-ellipsis-vertical "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        }),
                        _vm.canLoadMore &&
                        _vm.localTableItems.length !==
                          _vm.infiniteScrollList.length &&
                        !_vm.initialLoad &&
                        (_vm.localSearch === null || _vm.localSearch === "")
                          ? _c("tr", [
                              !_vm.excludeHeaders.includes("extraLesson")
                                ? _c("td", {
                                    style:
                                      _vm.contentBreakpoint === "xs"
                                        ? "width: 50px;"
                                        : "max-width: 50px; width: 50px;"
                                  })
                                : _vm._e(),
                              !_vm.excludeHeaders.includes("className")
                                ? _c("td")
                                : _vm._e(),
                              !_vm.excludeHeaders.includes("lessonNum")
                                ? _c("td")
                                : _vm._e(),
                              _vm.isDraggableDisabled &&
                              !_vm.excludeHeaders.includes("date")
                                ? _c("td")
                                : _vm._e(),
                              _vm.isDraggableDisabled &&
                              !_vm.excludeHeaders.includes("day")
                                ? _c("td")
                                : _vm._e(),
                              !_vm.excludeHeaders.includes("lessonTitle")
                                ? _c("td", [
                                    _c(
                                      "div",
                                      {
                                        style: {
                                          "margin-right": _vm.excludeHeaders.includes(
                                            "sorter"
                                          )
                                            ? "220px"
                                            : "130px"
                                        },
                                        attrs: { align: "center" }
                                      },
                                      [
                                        _vm.isLoadingMore
                                          ? _c("v-progress-circular", {
                                              attrs: {
                                                color: "primary",
                                                indeterminate: ""
                                              }
                                            })
                                          : _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "font-size": "12px"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "scrollDownToLoadMoreLabel"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [_vm._v("fal fa-arrow-down")]
                                                )
                                              ],
                                              1
                                            )
                                      ],
                                      1
                                    )
                                  ])
                                : _vm._e(),
                              !_vm.excludeHeaders.includes("sorter")
                                ? _c("td")
                                : _vm._e(),
                              !_vm.excludeHeaders.includes("action")
                                ? _c("td")
                                : _vm._e()
                            ])
                          : _vm._e()
                      ],
                      2
                    )
                  : _c("tbody", [
                      _c("tr", { staticClass: "v-data-table__empty-wrapper" }, [
                        _c("td", { attrs: { colspan: headers.length } }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("noDataAvailableLabel")) + " "
                          )
                        ])
                      ])
                    ]),
                _c("div", {
                  ref: "sentinel",
                  staticClass: "sentinel",
                  style: {
                    "margin-bottom":
                      _vm.canLoadMore &&
                      !_vm.isLoadingMore &&
                      _vm.localTableItems.length !==
                        _vm.infiniteScrollList.length
                        ? "70px"
                        : ""
                  }
                })
              ]
            }
          }
        ]),
        model: {
          value: _vm.selectedRows,
          callback: function($$v) {
            _vm.selectedRows = $$v
          },
          expression: "selectedRows"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }